.modal {
  display: flex;
  align-items: center;
  justify-content: 'center';
}
.modalUpdate {
  padding: auto;
}
.loader {
  z-index: 15000;
  align-items: center;
  align-self: center;
  background-color: aqua;
}

.isDisabled {
  background-color: #eee;
  color: #aaa;
}
