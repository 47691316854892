.editor-class {
  border: 1px solid;
  border-color: rgb(211, 209, 209);
  border-radius: 5px;
  padding: 10px;
}
.card {
  margin-top: 10px;
}
.textAlert {
  color: red;
  padding-left: 10px;
}
.DraftEditor-root {
  height: 200px;
}
